<template>
  <div class="container">
    <div class="row">
        <div class="col-12 col-lg-6 c g">
            <div class="card">
                <div class="card-header text-center">
                    <h4 class="text-center c">
                        يمكنك الآن <strong style="color: green">الاشترااك في جميع خدمات النظام</strong> مقابل 2 ريال لكل طالب.
                    </h4>
                    <span class="text-center g c">مثال: اذا كان لديك 200 طالب ستدفع 400 ريال وتحصل على اشتراك لـ50 معلم.</span>
                </div>
                <div class="card-body">
                    <div class="form-group">
                      <h5 for="">عدد الطلاب</h5>
                      <input type="number"
                        class="form-control form-control-lg" v-model="count">
                      <small id="helpId" :class="count < 200 ? 'form-text text-danger' : 'form-text text-muted'">اقل عدد هو 200 طالب</small>
                    </div>
                    <div class="form-group">
                    <h5 for="">اختر مدة الاشتراك</h5>
                        <select class="form-control form-control-lg" v-model="plan">
                            <option :value="2">لمدة فصل دراسي (2 ريال لكل طالب)</option>
                            <option :value="5">لمدة عام دراسي (5 ريال لكل طالب)</option>
                        </select>
                    </div>
                    <span style="font-size: 14px">
                        <span class="g">العرض يشمل الاشتراك في:</span>
                        <br>
                        <ul>
                            <li>
                                نظام تحضير الطلاب
                            </li>
                            <li>
                                نظام غياب المعلمين
                            </li>
                            <li>
                                ارسال الشهادات ومواد الاكمال
                            </li>
                            <li>
                                الاستبانات والنماذج والكشوف
                            </li>
                            <li>
                                نظام غياب الحصص
                            </li>
                            <li>
                                اعداد اللجان
                            </li>
                            <li>
                                نظام مصروفي
                            </li>
                            <li>
                                نظام المناداة والاتسئذان
                            </li>
                            <li>
                                الجدول المدرسي
                            </li>
                        </ul>
                    </span>
                    <b-form-checkbox :unchecked-value="false"
                        v-model="whats" switch
                        :value="true">
                        <h5 style="margin-top: 5px">
                            اشتراك في الواتس الذكي (الواتس التلقائي) لمدة 3 اشهر.
                        </h5>
                    </b-form-checkbox>
                    <div class="col-12 table-responsive" style="min-height: 0px;">
                        <table class="table table-hover table-bordered">
                            <tbody>
                                <tr>
                                    <td>
                                        تكلفة العرض
                                    </td>
                                    <td>
                                        {{ count*plan }} ريال
                                    </td>
                                </tr>
                                <tr v-if="whats">
                                    <td>
                                        الواتس الذكي
                                    </td>
                                    <td>
                                        300 ريال
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        الضريبة 15%
                                    </td>
                                    <td>
                                        {{ ((count*plan + (whats ? 300 : 0))*0.15).toFixed(0) }} ريال
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        اجمالي المطلوب
                                    </td>
                                    <td>
                                        {{ (((count*plan + (whats ? 300 : 0))*0.15) + (count*plan + (whats ? 300 : 0))).toFixed(0) }} ريال 
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-12 text-center">
                        <div class="col-12 g col-lg-6 c">
                            <p>
                                دفع عن طريق:
                            </p>
                            <button class="btn btn-success btn-block" v-if="pay_online" @click="manual = false; buynow()">
                                <i class="fa fa-cc-visa"></i>
                                دفع بالفيزا (بنك الراجحي)
                            </button> 
                            <button class="btn btn-primary btn-block" v-if="pay_online" @click="manual = false; buynow()">
                                <i class="fa fa-apple"></i>
                                دفع بـ Apple Pay
                            </button> 
                            <button class="btn btn-secondary btn-block" @click="manual = true;" v-b-modal.manual>
                                <i class="fa fa-bank"></i>
                                تحويل بنكي
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <b-modal id="manual" title="تحويل بنكي" hide-footer>
        <code style="font-size: 16px" v-html="manual_info">
        </code>
        <hr>
        بعد التحويل قم بملئ الحقول التالية:
        <br><br>
        <div class="form-group">
            <span for="">ايصال التحويل</span>
            <input type="file"
            class="form-control" id="ingredient_file" @change="uploadImage()" accept=".jpg,.png,.gif,.jpeg,.pdf">
        </div>
        <div id="progress-wrp">
            <div class="progress-bar"></div>
            <div class="status">0%</div>
        </div>
        <div class="form-group">
          <span for="">ملاحظات (اختياري)</span>
          <textarea class="form-control" v-model="manual_notes" rows="3" placeholder="اكتب اي ملاحظات... (اختياري)"></textarea>
        </div>
        <div class="col-12 text-center g">
            <button class="btn btn-success" @click="buynow()">
                ارسال <i class="fa fa-arrow-left"></i>
            </button>
        </div>
    </b-modal>
  </div>
</template>

<script>
import { VBModal, BModal, BFormCheckbox } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BFormCheckbox, BModal
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
    data(){
        return {
            user: JSON.parse(localStorage.getItem('user')),
            count: 200,
            plan: 2,

            manual: false,
            whats: false,
            manual_info: "",
            pay_online: false,
            manual_file: "",
            manual_notes: "",
        }
    },
    created(){
        if(!checkPer("subscribe")){
            this.$router.push('/NotPermitted')
            return false;
        }
        var g = this;
        $.post(api + '/user/subscribe/subscribe-html', {
            jwt: this.user.jwt,
            service: "العرض 1",
            plan: this.plan,
            students: 0,
            teachers: 0
        }).then(function(r){
            g.loading = false
            g.manual_info = r.response.reseller.manual_info;
            g.pay_online = r.response.reseller.online_pay;
        }).fail(function(){
            g.loading = false
        })
    },
    methods: {
        buynow(){
            var g = this;
            if(g.count < 200 && !window.location.href.includes("force")){
                return alert("اقل عدد للاشتراك هو 200 طالب", 200)
            }
            alert("جاري التحميل...")
            $.post(api + '/user/subscribe/create-order', {
                jwt: this.user.jwt,
                service: "العرض 1",
                plan: this.plan,
                count: this.count,
                manual: this.manual,
                whats: this.whats,
                manual_file: this.manual_file,
                manual_notes: this.manual_notes,
                force: window.location.href.includes("force") ? 'yes' : null
            }).then(function(r){
                if(r.status == 100){
                    eval(r.response)
                }else{
                    alert("حدث خطأ, " + r.response, 200)
                }
            }).fail(function(){
                alert("حدث خطأ في الاتصال", 200)
            })
        },
        uploadImage(){
            var g = this;
            var Upload = function (file) {
                this.file = file;
            };

            Upload.prototype.getType = function () {
                return this.file.type;
            };
            Upload.prototype.getSize = function () {
                return this.file.size;
            };
            Upload.prototype.getName = function () {
                return this.file.name;
            };
            Upload.prototype.progressHandling = function (event) {
                var percent = 0;
                var position = event.loaded || event.position;
                var total = event.total;
                var progress_bar_id = "#progress-wrp";
                if (event.lengthComputable) {
                percent = Math.ceil((position / total) * 100);
                }
                // update progressbars classes so it fits your code
                $(progress_bar_id + " .progress-bar").css("width", +percent + "%");
                $(progress_bar_id + " .status").text(percent + "%");
            };
            $("#progress-wrp").fadeIn("slow");
            Upload.prototype.doUpload = function () {
                var that = this;
                var formData = new FormData();

                // add assoc key values, this will be posts values
                formData.append("file", this.file, this.getName());
                formData.append("jwt", g.user.jwt);

                $.ajax({
                type: "POST",
                url: api + '/user/general/upload',
                xhr: function () {
                    var myXhr = $.ajaxSettings.xhr();
                    if (myXhr.upload) {
                    myXhr.upload.addEventListener(
                        "progress",
                        that.progressHandling,
                        false
                    );
                    }
                    return myXhr;
                },
                success: function (data) {
                    $("#progress-wrp").fadeOut("slow");
                    // your callback here
                    if(data.status != 100){
                        alert("هذه الصورة غير مدعومة", 200)
                        return;
                    }
                    g.manual_file = data.response
                },
                error: function (error) {
                    // handle error
                    alert("حدث خطأ");
                },
                async: true,
                data: formData,
                cache: false,
                contentType: false,
                processData: false,
                timeout: 60000,
                });
            };
            var file = $("#ingredient_file")[0].files[0];
            var upload = new Upload(file);
            upload.doUpload();
        },
        done(){
            var g = this;
            if(g.count < 200 && !window.location.href.includes("force")){
                return alert("اقل عدد للاشتراك هو 200 طالب.", 200)
            }
            
        }
    }
}
</script>


<style scoped>
#progress-wrp {
  border: 1px solid #0099cc;
  padding: 1px;
  position: relative;
  height: 30px;
  border-radius: 3px;
  margin: 10px;
  text-align: left;
  background: #fff;
  box-shadow: inset 1px 3px 6px rgba(0, 0, 0, 0.12);
  display: none;
}

#progress-wrp .progress-bar {
  height: 100%;
  border-radius: 3px;
  background-color: greenyellow;
  width: 0;
  box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.11);
}

#progress-wrp .status {
  top: 3px;
  left: 50%;
  position: absolute;
  display: inline-block;
  color: #000000;
}
</style>